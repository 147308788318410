import React from 'react';
import "./parceiros.css";

function Parceiros() {
    return <section id='banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-7'>
                    <div className='container'>
                        <a href="/#"><img src="parceiros.png" className='Parceiros' /></a>
                    </div>
                </div>

                <div className='col-lg-5 borda_button'>
                    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner parceiros_img">
                            <div className="carousel-item active" data-bs-interval="3000">
                                <img src="afeal.png" className='Afeal' />
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <img src='aluminorte.png' className='Aluminorte' />
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <img src='brazil_glass.png' className='Brazil Glass' />
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <img src='cda.png' className='CDA' />
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <a href='/#'><img src='dispac.png' className='Dispac' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <a href='/#'><img src='hydro.png' className='Hydro' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <a href='/#'><img src='olga.png' className='Olga Collor' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <a href='/#'><img src='perfil.png' className='Perfil' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <a href='/#'><img src='still.png' className='Still' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="3000">
                                <a href='/#'><img src='udinese.png' className='Udinese' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section >;
}

export default Parceiros;
import React from 'react';
import "./obras.css";

function Obras() {
    return <section id="obra">
        <div className="container">
            <div className="row text-center">

                <div className="titulo">
                    <section id="objetivo">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <img src="objetivos.png"></img>
                                </div>
                            </div>
                        </div>
                    </section>
                    <h2>Obras e Projetos</h2>
                    <p>Portifólio</p>
                </div>
            </div>
            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>CCB - Congregação Cristã no Brasil</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Santo André - SP</p>
                            <img src="ccb_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construcompany - SP</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Floresta</p>
                            <img src="floresta_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>KAVAK</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Santana de Parnaíba</p>
                            <img src="kavak_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>MercadoCAR</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Osasco - SP</p>
                            <img src="mercado_car_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Minha Casa Minha Vida</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Associação Gente Inocente</p>
                            <img src="minha_casa_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construtora Seker</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Neo Vitta - Osasco</p>
                            <img src="neo_vitta_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">
                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construtora Nivelar</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Residencial Alto da Serra - Osasco</p>
                            <img src="nivelar_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>


                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construtora A&B - Radial Office</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Edifício Comercial Radial Office</p>
                            <img src="radial_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Transferrari</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Embú das Artes - SP</p>
                            <img src="transferrari_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">


                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construtora Vesta</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Osasco - SP</p>
                            <img src="vesta_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construtora Lock</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>VILLA 11 - Pinheiros - SP</p>
                            <img src="vila_11_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>VILA GALÉ</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Maceió - AL</p>
                            <img src="vila_gale_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row text-center">

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construtora Yazigui Chamas</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Residencial Iliria - SP</p>
                            <img src="yazigui_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Idea ZARVOS</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Pinheiros - SP</p>
                            <img src="zarvos_pinheiros_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>

                <div className="col-lg-4">
                    <div className="card">
                        <div className="card-header"><h5>Construtora Zatz</h5></div>
                        <div className="card-body">
                            <h2></h2>
                            <p>Osasco - SP</p>
                            <img src="zatz_1.png" alt="" height="250" />
                        </div>
                    </div>
                </div>
                <section id="projetos">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <img src="projetos.png"></img>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </div>

    </section >;
}

export default Obras;

function Valores() {
    return <section id="valores">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <img src="nossos_valores.png"></img>
                </div>
                <div className="col-lg-6">
                    <img src="nossa_missao.png"></img>
                </div>
            </div>
        </div>
    </section>;
}


export default Valores;
import React from 'react';
import "./menu.css";

function Menu() {

    return <nav className="navbar fixed-top navbar-expand-md navbar-dark">
        <div className="container-fluid">

            <a className="navbar-brand" href="/#">
                <img src="logo_transp.png" alt="" height="50" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="h4 nav-link" aria-current="page" href="/#">Home</a>
                    </li>
                    <li className="nav-item">
                        <a className="h4 nav-link" aria-current="page" href="/#">História</a>
                    </li>
                    <li className="nav-item">
                        <a className="h4 nav-link" aria-current="page" href="/#features">Equipe</a>
                    </li>
                    <li className="nav-item">
                        <a className="h4 nav-link" aria-current="page" href="/#obra">Portifólio</a>
                    </li>
                    <li className="nav-item">
                        <a className="h4 nav-link" aria-current="page" href="/#valores">Valores e Missão</a>
                    </li>
                    <li className="nav-item">
                        <a className="h4 nav-link" aria-current="page" href="/#contato">Contato</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>;
}

export default Menu;
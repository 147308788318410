import React from 'react';

import "./features.css";

function Features() {
    return <section id="features">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 feature-box">
                    <img src="especialista.png"></img>
                    <h4>Equipe Especializada</h4>
                    <p></p>
                    <p>A Flamaph conta com um corpo técnico altamente qualificado, comprometido em oferecer as melhores soluções no segmento de alumínio.</p>
                    <p></p>
                </div>

                <div className="col-lg-4 feature-box">
                    <img src="diversificacao.png"></img>
                    <p></p>
                    <h4>Diversificação de Atuação</h4>
                    <p></p>
                    <p>Os profissionais da Flamaph se dedicam a se especializar continuamente, diversificando suas competências para atender às diversas demandas do mercado.</p>
                    <p></p>
                </div>
                <div className="col-lg-4 feature-box">
                    <img src="qualidade.png"></img>
                    <p></p>
                    <h4>Qualidade e Eficiência</h4>
                    <p></p>
                    <p>A empresa se destaca na fabricação, montagem, instalação, acompanhamento e fiscalização de seus serviços, garantindo qualidade e eficiência em cada projeto.</p>
                    <p></p>
                </div>

            </div>
        </div>
    </section>;
}

export default Features;

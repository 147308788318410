import React from 'react';
import "./banner.css";

function Banner() {
    return <section id='banner'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='container'>
                        <a href="/#"><img src="portaria.png" className='Sobre à Flamaph' /></a>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='container'>
                        <a href="/#"><img src="sobre_flamaph_txt.png" className='Sobre à Flamaph' /></a>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='container'>
                        <img src="sobre_flamaph2.png" className='Sobre à Flamaph' />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <p />
                    <h2 className='lblObra'>OBRAS REALIZADAS:</h2>
                    <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active" data-bs-interval="4000">
                                <img src="ccb.png" className='CCD Santo André' />
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <img src='floresta.png' className='Floresta' />
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <img src='kavak.png' className='KAVAK' />
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <img src='mercado_car.png' className='Mercado Car - osasco' />
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='minha_casa.png' className='Minha Casa Minha Vida' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='neo_vitta.png' className='Neo vitta' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='nivelar.png' className='Nivelar' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='radial.png' className='Radial' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='transferrari.png' className='Transferrari' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='vesta.png' className='Vesta' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='vila_11.png' className='Villa 11' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='vila_gale.png' className='Vila Galé' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='yazigui.png' className='Yazigui' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='zarvos_pinheiros.png' className='idea Zarvos Pinheiros' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='zarvos_uri.png' className='idea Zarvos Uri' /></a>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <a href='/#'><img src='zatz.png' className='Zatz' /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section >;
}

export default Banner;